import { render, staticRenderFns } from "./PnTextArea.vue?vue&type=template&id=e232bcce&scoped=true&"
import script from "./PnTextArea.vue?vue&type=script&lang=js&"
export * from "./PnTextArea.vue?vue&type=script&lang=js&"
import style0 from "./PnTextArea.vue?vue&type=style&index=0&id=e232bcce&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e232bcce",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src1768090063/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e232bcce')) {
      api.createRecord('e232bcce', component.options)
    } else {
      api.reload('e232bcce', component.options)
    }
    module.hot.accept("./PnTextArea.vue?vue&type=template&id=e232bcce&scoped=true&", function () {
      api.rerender('e232bcce', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/app/js/vuejs/components/patient-navigation/report/PnTextArea.vue"
export default component.exports