import { render, staticRenderFns } from "./ColumnBirthday.vue?vue&type=template&id=68ec0656&"
import script from "./ColumnBirthday.vue?vue&type=script&lang=js&"
export * from "./ColumnBirthday.vue?vue&type=script&lang=js&"
import style0 from "./ColumnBirthday.vue?vue&type=style&index=0&lang=postcss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src1768090063/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('68ec0656')) {
      api.createRecord('68ec0656', component.options)
    } else {
      api.reload('68ec0656', component.options)
    }
    module.hot.accept("./ColumnBirthday.vue?vue&type=template&id=68ec0656&", function () {
      api.rerender('68ec0656', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/app/js/vuejs/components/organization/customer-management/columns/ColumnBirthday.vue"
export default component.exports