import Vue from "../vue.js"

import { findKey, isUndefined, merge } from "lodash"

import { Constants } from "../models/Constants"
import { Permissions } from "../models/Permissions"

export default {
  namespaced: true,
  state: {
    appointment_id: null,
    chat_sid: null,
    created_at: null,
    duration: null,
    end_date: null,
    group: {},
    requester_id: null,
    assigned_id: null,
    report_author_id: null,
    history: [],
    history_id: null,
    participants: [],
    payment: null,
    payment_id: null,
    start_date: {},
    status: null,
    status_desc: "",
    term: {},
    term_id: null,
    thread_id: null,
    type: null,
    updated_at: null,
    price: null,
    report_content: null,
    timeline: null,
    consultation_type: null,
    location: null,
    enquiry_requester: {},
    enquiry_info: {},
    suppress_start_prompt: false,
    is_user_group_member: false,
    esearch_source_group: null,
  },
  mutations: {
    update(state, body) {
      state = merge(state, body)
      if (body.hasOwnProperty("term")) {
        Vue.set(state, "term", body.term)
      }
      if (body.hasOwnProperty("participants")) {
        Vue.set(state, "participants", body.participants)
      }
      if (body.hasOwnProperty("timeline")) {
        Vue.set(state, "timeline", body.timeline)
      }
      if (body.hasOwnProperty("esearch_source_group")) {
        Vue.set(state, "esearch_source_group", body.esearch_source_group)
      }
      if (body.hasOwnProperty("history")) {
        Vue.set(state, "history", body.history)
      }
    },

    updateReportContent(state, content) {
      Vue.set(state, "report_content", content)
    },

    updateParticipant(state, participant) {
      let key = findKey(state.participants, { user_id: participant.user_id })
      if (isUndefined(key)) return
      Vue.set(state.participants, key, merge(state.participants[key], participant))
    },

    upsertInvitation(state, invitation) {
      let key = findKey(state.invitations, { invitation_id: invitation.invitation_id })
      if (isUndefined(key)) state.invitations.push(invitation)
      else Vue.set(state.invitations, key, merge(state.invitations[key], invitation))
    },

    suppressStartPrompt(state) {
      // if user clicks Start call in timeline, do not show "Join the call" modal
      Vue.set(state, "suppress_start_prompt", true)
    },
  },
  getters: {
    getSender: (state) => () => {
      return state.participants.find((participant) => participant.is_requester)
    },
    getRecipient: (state) => () => {
      return state.participants.find((participant) => participant.is_recipient)
    },
    getCustomer: (state) => () => {
      return window.hasOwnProperty("customer") ? JSON.parse(window.customer) : null
    },
    actualParticipants: (state) => {
      return state.participants.filter((participant) => participant.appendedGroupMember === false)
    },
    getMe: (state, _, rootState) => () => {
      const user_id = rootState.user.user_id
      return state.participants.find((participant) => participant.user_id === user_id)
    },
    hasAppointmentPermission: (state, getters) => (permission_name) => {
      if (typeof getters.getMe() === typeof undefined) return false
      return getters.getMe().permissions.indexOf(permission_name) !== -1
    },
    participantHasPermission: (state) => (participant_id, permission_name) => {
      const participant = state.participants.find(
        (participant) => participant.participant_id === participant_id
      )
      return participant === undefined
        ? false
        : participant.permissions.indexOf(permission_name) !== -1
    },
    canManageParticipants: (state, getters) => {
      return (
        getters.hasAppointmentPermission(Permissions.APPOINTMENT_SET_PARTICIPANT_CALL) ||
        getters.hasAppointmentPermission(Permissions.GROUP_GLOBAL_REQUESTS_SET_PARTICIPANT_CALL) ||
        getters.hasAppointmentPermission(Permissions.GROUP_GLOBAL_REQUESTS_SET_REPORT_AUTHOR)
      )
    },
    canInvite: (state, getters) => {
      return (
        getters.hasAppointmentPermission(Permissions.APPOINTMENT_INVITE_PARTICIPANTS) ||
        getters.hasAppointmentPermission(Permissions.GROUP_GLOBAL_REQUESTS_INVITE_PARTICIPANTS)
      )
    },
    canInviteOrShare: (state, getters) => {
      return (
        getters.hasAppointmentPermission(Permissions.APPOINTMENT_INVITE_PARTICIPANTS) ||
        getters.hasAppointmentPermission(Permissions.GROUP_GLOBAL_REQUESTS_INVITE_PARTICIPANTS) ||
        getters.hasAppointmentPermission(Permissions.GROUP_GLOBAL_REQUESTS_SHARE)
      )
    },
    canShare: (state, getters) => {
      return getters.hasAppointmentPermission(Permissions.GROUP_GLOBAL_REQUESTS_SHARE)
    },
    canUserSeeAcceptedConsents: (state, getters) => (user_id) => {
      if (getters.isRequester(user_id) === true) {
        return true
      }

      return state.is_user_group_member
    },
    canMuteParticipant: (state, getters) => {
      return getters.hasAppointmentPermission(
        Permissions.GROUP_GLOBAL_REQUESTS_MUTE_PARTICIPANTS_NOTIFICATIONS
      )
    },
    canRemoveParticipant: (state, getters) => {
      return (
        getters.hasAppointmentPermission(Permissions.GROUP_GLOBAL_REQUESTS_REMOVE_PARTICIPANTS) ||
        getters.hasAppointmentPermission(Permissions.APPOINTMENT_REMOVE_PARTICIPANTS)
      )
    },
    getContextRecipient: (state, getters) => (user_id) => {
      const callManagerUser = getters.getParticipantsOnTheCall.find((participant) =>
        getters.participantHasPermission(
          participant.participant_id,
          Permissions.APPOINTMENT_MANAGE_CALL
        )
      )

      if (callManagerUser === void 0) {
        return {
          name: state.group.name,
          avatar_url: state.group.avatar_url,
          user_id: null,
        }
      } else if (callManagerUser.user_id === user_id) {
        return {
          name: `${state.requester.name} ${state.requester.surname}`,
          avatar_url: state.requester.avatar_url,
          type: "requester",
          user_id: state.requester.user_id,
        }
      } else {
        return {
          name: callManagerUser.display_name,
          avatar_url: callManagerUser.avatar_url,
          type: "group",
          user_id: callManagerUser.user_id,
        }
      }
    },
    getContextRecipientOfAppointment: (state, getters) => (appointment, user_id) => {
      if (appointment.requester_id === user_id) {
        return {
          name: appointment.group.name,
          avatar_url: appointment.group.avatar_url,
          user_id: appointment.assigned_id,
        }
      }
      return appointment.requester !== null
        ? {
            name: `${appointment.requester?.name} ${appointment.requester?.surname}`,
            avatar_url: appointment.requester?.avatar_url,
            user_id: appointment.requester_id,
          }
        : {
            name: "-",
            avatar_url: null,
            user_id: null,
          }
    },
    getReportContent: (state) => {
      return state.report_content
    },
    getTimeline: (state) => () => {
      return state.timeline
    },
    isRequester: (state) => (user_id) => {
      return state.requester_id === user_id
    },
    isReporter: (state) => (user_id) => {
      return state.report_author_id === user_id
    },
    isAssigned: (state) => (user_id) => {
      return state.assigned_id === user_id
    },
    isClosed: (state) => {
      return state.status < 0
    },
    isParticipant: (state, getters) => (user_id) => {
      return (
        getters.actualParticipants.find((participant) => participant.user_id === user_id) !==
        undefined
      )
    },
    isAppointmentAssigned: (state) => {
      return state.assigned_id !== null
    },
    isSecondOpinionOnly: (state) => {
      return state.consultation_type === Constants.Appointment.CONSULTATION_OPINION
    },
    isVideoCall: (state) => {
      return state.consultation_type === Constants.Appointment.CONSULTATION_CALL_OPINION
    },
    isDiagnosticTest: (state) => {
      return state.consultation_type === Constants.Appointment.CONSULTATION_DIAGNOSTIC_TEST
    },
    isOverseasTreatment: (state) => {
      return state.consultation_type === Constants.Appointment.CONSULTATION_OVERSEAS_TREATMENT
    },
    isFractureCover: (state) => {
      return state.consultation_type === Constants.Appointment.CONSULTATION_FRACTURE_COVER
    },
    getParticipantsOnTheCall: (state) => {
      return state.participants.filter((participant) => participant.on_the_call === true)
    },
    isStartPromptSuppressed: (state) => {
      return state.suppress_start_prompt
    },
  },
  actions: {
    fetch({ commit }, appointment_id) {
      return Vue.http
        .get("/ajax/appointment/" + appointment_id)
        .then((response) => {
          commit("update", response.data.data)
        })
        .catch(() => {
          // Silent catch
        })
    },
    fetchDraftReport({ commit }, appointment_id) {
      return Vue.http
        .get("/ajax/appointment/" + appointment_id + "/report/draft?type=report")
        .then((response) => {
          commit("updateReportContent", response.data.data)
        })
    },
    fetchHistory({ commit }, appointment_id) {
      return new Promise((resolve, reject) => {
        Vue.http
          .get("/ajax/appointment/" + appointment_id + "/history")
          .then((response) => {
            commit("update", response.data.data)
            resolve(response)
          })
          .catch((response) => reject(response))
      })
    },
    updateReportContent({ commit }, { appointment_id, message }) {
      return Vue.http
        .post("/ajax/appointment/" + appointment_id + "/report/draft", { message: message })
        .then((response) => {
          commit("updateReportContent", response.data.data)
        })
    },
    sendReport({ commit }, { appointment_id, message, cc, uploaded_files }) {
      return Vue.http
        .post("/ajax/appointment/" + appointment_id + "/report", {
          message: message,
          cc: cc,
          uploaded_files: uploaded_files,
        })
        .then((response) => {
          commit("updateReportContent", response.data.data)
        })
    },
    setConsultationType({ commit }, { appointment_id, type }) {
      return Vue.http.post("/ajax/appointment/" + appointment_id + "/consultation/switch", {
        type: type,
      })
    },
    accept({ commit }, { appointment_id, price }) {
      return Vue.http.post("/ajax/appointment/" + appointment_id + "/accept", price)
    },
    assign({ commit }, payload) {
      return Vue.http.post("/ajax/appointment/" + payload.appointment_id + "/assign", payload)
    },
    inviteParticipant({ commit }, { appointment_id, email, message, permissions }) {
      return Vue.http
        .post("/ajax/appointment/" + appointment_id + "/participants", {
          email: email,
          message: message,
          permissions: permissions,
        })
        .then((response) => {
          commit("upsertInvitation", response.data.data)
        })
    },
    manageParticipant({ commit }, { appointment_id, participant_id, action }) {
      return Vue.http.post("/ajax/appointment/" + appointment_id + "/participants/manage", {
        participant_id: participant_id,
        params: {
          action: action,
        },
      })
    },
    setParticipantRoomState({ commit }, { identity, state }) {
      commit("updateParticipant", { user_id: identity, room_state: state })
    },
    socket_appointment(context, appointment) {
      if (context.state.appointment_id === appointment.appointment_id) {
        context.commit("update", appointment)
      }
    },
  },
}
