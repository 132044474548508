//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { email, maxLength, minLength, required } from "vuelidate/lib/validators"
import { isAfter, isPastDate } from "../../../plugins/vuelidateAdditional"
import { castQueryStringToObject, phoneRegexValidator } from "../../../../utils"

import AutocompleteDisable from "../../../plugins/AutocompleteDisable"
import BirthdayInput from "../../../plugins/BirthdayInput.vue"

export default {
  mixins: [AutocompleteDisable],
  props: {
    organizationId: { type: String, required: true },
    componentId: { type: String, required: true },
    componentUrl: { type: String, required: true },
  },
  data() {
    return {
      processing: false,
      form: {
        name: "",
        surname: "",
        email: "",
        gender: "",
        birthday: "",
        phone: "",
        country: "",
        city: "",
        customer_plan_id: "",
        timezone: this.$moment.tz.guess(),
      },
      countryCode: null,
      phoneNumber: null,
      countries: window.countries || [],
      customerPlans: [],
      readOnly: [],
      hookId: null,
    }
  },
  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(50),
      },
      surname: {
        required,
        maxLength: maxLength(50),
      },
      email: {
        required,
        email,
      },
      gender: {
        required,
      },
      birthday: {
        required,
        userBirthday: isAfter("01.01.1900", "DD.MM.YYYY"),
        date: isPastDate(),
      },
      phone: {
        phoneRegexValidator,
        minLength: minLength(9),
        maxLength: maxLength(20),
      },
      country: {
        required,
      },
      city: {
        maxLength: maxLength(100),
      },
      tdCustomerPlanId: {
        required,
      },
    },
  },
  computed: {
    invalid() {
      return this.$v.form.$invalid
    },
    phoneCode() {
      const country = this.countryCodes.find((code) => code.value === this.countryCode)
      return country ? country.phone : null
    },
    countryCodes() {
      return [{ value: null, label: this.$t.get("common.choose-option") }].concat(
        window.countries
          .filter((country) => {
            return country.phone !== null
          })
          .sort((country) => country.name)
          .map((country) => {
            return {
              value: country.code,
              label: `${this.$t.get(country.translation_key)} (+${country.phone})`,
              phone: country.phone,
            }
          })
      )
    },
    phoneMask() {
      return {
        model: "phoneNumber",
        phone: true,
        phoneRegionCode: this.countryCode,
      }
    },
    number() {
      return `+${this.phoneCode} ${this.phoneNumber}`
    },
  },
  watch: {
    number(phone) {
      this.form.phone = phone
    },
  },
  components: {
    "birthday-input": BirthdayInput,
  },
  beforeMount() {
    this.fetchCustomerPlans().then(() => {
      const queryStringObject = castQueryStringToObject()
      if (queryStringObject.hasOwnProperty("populate") === true) {
        this.populateCustomerAddForm(queryStringObject)
      }
    })
  },
  methods: {
    cancel() {
      this.$emit("cancel")
    },
    isReadOnly(fieldName) {
      return this.readOnly.indexOf(fieldName) !== -1
    },
    populateCustomerAddForm(queryStringObject) {
      this.processing = true
      let params = {
        action: "populateCustomerAddForm",
        componentId: this.componentId,
      }
      params = { ...params, ...queryStringObject }
      return this.$http
        .post(this.componentUrl, params)
        .then((response) => {
          this.processing = false
          this.readOnly = response.data.readOnly
          this.hookId = response.data.hookId || null

          for (const [key, value] of Object.entries(response.data.fields || {})) {
            if (key === "birthday") {
              this.$set(this.form, key, this.$moment(value).format("DD.MM.YYYY"))
              continue
            }
            this.$set(this.form, key, value)
          }
        })
        .catch((response) => {
          this.processing = false
        })
    },
    fetchCustomerPlans() {
      this.processing = true
      let params = {
        action: "fetchCustomerPlans",
        componentId: this.componentId,
      }
      return this.$http
        .post(this.componentUrl, params)
        .then((response) => {
          this.processing = false
          this.customerPlans = response.data.data

          const defaultPlan = response.data.data.find((plan) => plan.default === true)
          this.form.tdCustomerPlanId = defaultPlan.customer_plan_id
        })
        .catch((response) => {
          this.processing = false
          this.$bridge.emit("addToast", response.response.data.message, "error")
        })
    },
    addCustomer() {
      this.processing = true
      let params = {
        action: "addCustomer",
        componentId: this.componentId,
        hookId: this.hookId,
      }
      return this.$http
        .post(this.componentUrl, { ...params, ...this.form })
        .then((response) => {
          this.processing = false
          this.resetForm()
          this.$bridge.emit("addToast", response.data.message, "success")
          this.$emit("customer-added", response.data.data.customer)
        })
        .catch((response) => {
          this.processing = false
          this.$bridge.emit("addToast", response.response.data.message, "error")
        })
    },
    resetForm() {
      this.form.name = ""
      this.form.surname = ""
      this.form.email = ""
      this.form.gender = ""
      this.form.birthday = ""
      this.form.phone = ""
      this.phoneNumber = ""
      this.form.country = ""
      this.form.city = ""
      this.$nextTick(() => this.$v.$reset())
    },
  },
}
